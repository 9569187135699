import { NgModule, NgModuleRef } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { IsFormBuilderState } from './modules/isformbuilder/isformbuilder.state';
import 'reflect-metadata';
import { IsEventState } from './isevent/isevent.state';
import { AngularSplitModule } from 'angular-split';
import {
  ConfigService,
  HttpErrorHandler,
  MessageService,
} from '@ic-builder/data-access-iscloud';
//import { WizardState } from './iswizard/iswizard.state';
import { NgxsModule } from '@ngxs/store';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTreeModule } from '@angular/material/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IsinputComponent } from './isinput/isinput.component';
import { IsinputenumerateComponent } from './isinputenumerate/isinputenumerate.component';
import { IswizpageComponent } from './iswizpage/iswizpage.component';
import { IswizardComponent } from './iswizard/iswizard.component';

import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { IsApplicationComponent } from './isapplication/isapplication.component';
import { IsApplicationState } from './isapplication/isapplication.state';
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { IstoolbarComponent } from './istoolbar/istoolbar.component';
import { IsbuttonComponent } from './isbutton/isbutton.component';
import { IsmenuComponent } from './ismenu/ismenu.component';
import { IslabelComponent } from './islabel/islabel.component';
import { EnumState } from './isenum/enum.state';
import { IscompilerService } from './iscompiler/iscompiler.service';
import { HighlightModule } from './highlight/highlight.module';
import { SelectionGridComponent } from './selection-grid/selection-grid.component';
import { RightclickmenuComponent } from './rightclickmenu/rightclickmenu.component';
import { IsBaseModule } from '@ic-builder/is-base';
//import { IsloaderComponent } from './isloader/isloader.component';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { IstreeComponent } from './istree/istree.component';
import { IsworkorderplannerComponent } from './isworkorderplanner/isworkorderplanner.component';
import { Isheadercolumn1Component } from './isgrid/isheader/isheadercolumn/isheadercolumn.component';
import { IsdialogComponent } from './isdialog/isdialog.component';
import { IsiconComponent } from './isicon/isicon.component';
//import { IscontextoverlayComponent } from './iscontextoverlay/iscontextoverlay.component';
import { SelectComponent } from './iscontrol/isselect/select.component';
import { InputComponent } from './iscontrol/isinput/input.component';
import { DateComponent } from './iscontrol/isdate/date.component';
import { NumberComponent } from './iscontrol/isnumber/number.component';
import { TimeFullComponent } from './iscontrol/istimefull/timefull.component';
import { TimesingleComponent } from './iscontrol/istimesingle/timesingle.component';
import { DatefullComponent } from './iscontrol/isdatefull/datefull.component';
import { IscolorpickerComponent } from './iscontrol/iscolorpicker/iscolorpicker.component';
import { IssizeinputComponent } from './iscontrol/issizeinput/issizeinput.component';
import { IssliderComponent } from './iscontrol/isslider/isslider.component';
import { SpeedometerComponent } from './widgets/speedometer/speedometer.component';
import { IsiconoptionComponent } from './iscontrol/isiconoption/isiconoption.component';
import { IspageformtestComponent } from './ispageformtest/ispageformtest.component';
import { IstaginputComponent } from './iscontrol/istaginput/istaginput.component';
import { IspageformtestitemComponent } from './ispageformtest/ispageformtestitem/ispageformtestitem.component';
import { Ismenu2Component } from './ismenu2/ismenu2.component';
import { Ismenuitem2Component } from './ismenu2/ismenuitem2/ismenuitem2.component';
import { PagedesignerComponent } from './pagedesigner/pagedesigner.component';
import { IsthemaComponent } from './isthema/isthema.component';
import { PanelComponent } from './iscontrol/panel/panel.component';
import { FormgroupComponent } from './iscontrol/formgroup/formgroup.component';
import { IsenumselectComponent } from './iscontrol/isenumselect/isenumselect.component';
import { MenuhandlerComponent } from './menuhandler/menuhandler.component';
import { MenuhandlercontainerComponent } from './menuhandlercontainer/menuhandlercontainer.component';
import { MenuhandleritemComponent } from './menuhandleritem/menuhandleritem.component';
import { IsgridComponent } from './isgrid/isgrid.component';
import { IsheaderComponent } from './isgrid/isheader/isheader.component';
import { IsbodyComponent } from './isgrid/isbody/isbody.component';
import { IsfooterComponent } from './isgrid/isfooter/isfooter.component';
import { IscelComponent } from './isgrid/isbody/iscel/iscel.component';
import { EditcelComponent } from './isgrid/isbody/editcel/editcel.component';
import { IscheckboxcelComponent } from './isgrid/isbody/ischeckboxcel/ischeckboxcel.component';
import { IsselectheaderComponent } from './isgrid/isheader/isselectheader/isselectheader.component';
import { IscheckboxComponent } from './iscontrol/ischeckbox/ischeckbox.component';
import { IsradioComponent } from './iscontrol/isradio/isradio.component';
import { Isenum2Component } from './iscontrol/isenum2/isenum2.component';
import { EditconfigComponent } from './isgrid/isbody/editconfig/editconfig.component';
import { FieldselectorComponent } from './isgrid/isheader/fieldselector/fieldselector.component';
import { IslabeldataComponent } from './islabeldata/islabeldata.component';
import { StylefuncComponent } from './isgrid/isbody/stylefunc/stylefunc.component';
import { CvcolorPipe } from './color/color-helpers.service';
import { TfrxqueryComponent } from './tfrxquery/tfrxquery.component';

import {
  IsdayComponent,
  IsmonthComponent,
  IsPlanner,
  IsVariablemonthPlanner,
  IsyearplannerComponent,
} from './isyearplanner/isyearplanner.component';
import { IsthemepickerComponent } from './isthemepicker/isthemepicker.component';
import { IsenumgridComponent } from './iscontrol/isenumgrid/isenumgrid.component';
import { IstimebarComponent } from './istimebar/istimebar.component';
import { IscollapsibleComponent } from './iscollapsible/iscollapsible.component';
import { IsfilterComponent } from './isfilter/isfilter.component';
import { IsleafexpressionComponent } from './isfilter/isleafexpression/isleafexpression.component';
import { IsexpressionComponent } from './isfilter/isexpression/isexpression.component';
import { IsgriddropdownComponent } from './isgrid/isgriddropdown/isgriddropdown.component';
import { IsfilterpanelComponent } from './isgrid/isfilterpanel/isfilterpanel.component';
import { DateValueAccessor } from './iscontrol/isdate/date-value-accessor';
import { GmapsComponent } from './modules/gmaps/gmaps.component';
import { SplitterComponent } from './iscontrol/splitter/splitter.component';
import {
  WidgetsRegistryModule,
  WidgetsRegistryService,
} from '@ic-builder/widgets-registry';
import { IsStyleInputComponent } from './isfilter/is-style-input/is-style-input.component';
import { IsStyleSelectComponent } from './isfilter/is-style-select/is-style-select.component';
//import { WidgetsLoaderModule } from '@ic-builder/widgets-loader';

import { IsBaseControlsModule } from '@ic-builder/is-base-controls';
import { IsenumpanelComponent } from './iscontrol/isenumpanel/isenumpanel.component';
import { IscheckboxpanelComponent } from './iscontrol/ischeckboxpanel/ischeckboxpanel.component';
import { IsemailComponent } from './iscontrol/isemail/isemail.component';
import { IsexpfilterComponent } from './isexpfilter/isexpfilter.component';
import { IsexpcontainerComponent } from './isexpfilter/isexpcontainer/isexpcontainer.component';
import { AndOrComponent } from './isexpfilter/and-or/and-or.component';
import { EditenumComponent } from './isgrid/isbody/editenum/editenum.component';
import { Isinput2Component } from './iscontrol/isinput2/isinput2.component';
import { Issizeinput2Component } from './iscontrol/issizeinput2/issizeinput2.component';
import { IsperiodselectorComponent } from './isperiodselector/isperiodselector.component';
import { Menuhandler3Component } from './menuhandler3/menuhandler3.component';
import { Menuhandleritem3Component } from './menuhandler3/menuhandleritem3/menuhandleritem3.component';
import { IsimageComponent } from './isimage/isimage.component';
import { IsframeComponent } from './isframe/isframe.component';
import { Isradio2Component } from './iscontrol/isradio2/isradio2.component';
import { IslabeldatadelimiterComponent } from './islabeldatadelimiter/islabeldatadelimiter.component';
import { IsCompiteratorComponent } from './iscompiterator/iscompiterator.component';
import { IserrorComponent } from './iserror/iserror.component';
import { IsuploadComponent } from './iscontrol/isupload/isupload.component';
import { Isyearplanner2Component } from './isyearplanner2/isyearplanner2.component';
import { Ismonth2Component } from './isyearplanner2/ismonth2/ismonth2.component';
import { Isday2Component } from './isyearplanner2/isday2/isday2.component';
import { SpinnerOverlayService } from './spinner-overlay/spinner-overlay.service';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { SpinnerInterceptor } from './spinner-overlay/spinner.interceptor';
import { IsclockComponent } from './isclock/isclock.component';
import { SignatureComponent } from './signature/signature.component';
import { IssignatureComponent } from './issignature/issignature.component';
import { IstoggleComponent } from './iscontrol/istoggle/istoggle.component';
import { IsgmapsComponent } from './isgmaps/isgmaps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SliderComponent } from './slider/slider.component';
import { IsfrequentionComponent } from './iscontrol/isfrequention/isfrequention.component';
import { IsfileinputComponent } from './iscontrol/isfileinput/isfileinput.component';

let instanceNr = 0;

@NgModule({
  declarations: [
    IsinputComponent,
    IsinputenumerateComponent,
    IswizpageComponent,
    IswizardComponent,
    IsApplicationComponent,
    IstoolbarComponent,
    IsbuttonComponent,
    IsmenuComponent,
    IslabelComponent,
    PanelComponent,
    SelectionGridComponent,
    RightclickmenuComponent,
    IstreeComponent,
    IsworkorderplannerComponent,
    Isheadercolumn1Component,
    IsdialogComponent,
    IsiconComponent,
    SelectComponent,
    InputComponent,
    DateComponent,
    NumberComponent,
    TimeFullComponent,
    TimesingleComponent,
    DatefullComponent,
    IscolorpickerComponent,
    IssizeinputComponent,
    IssliderComponent,
    SpeedometerComponent,
    IsiconoptionComponent,
    IspageformtestComponent,
    IstaginputComponent,
    IspageformtestitemComponent,
    Ismenu2Component,
    Ismenuitem2Component,
    PagedesignerComponent,
    IsthemaComponent,
    FormgroupComponent,
    IsenumselectComponent,
    MenuhandlerComponent,
    MenuhandlercontainerComponent,
    MenuhandleritemComponent,
    IsgridComponent,
    IsheaderComponent,
    IsbodyComponent,
    IsfooterComponent,
    IscelComponent,
    EditcelComponent,
    IscheckboxcelComponent,
    IsselectheaderComponent,
    IscheckboxComponent,
    IsradioComponent,
    Isenum2Component,
    EditconfigComponent,
    FieldselectorComponent,
    IslabeldataComponent,
    StylefuncComponent,
    IsPlanner,
    IsVariablemonthPlanner,
    IsyearplannerComponent,
    IsmonthComponent,
    IsdayComponent,
    IsthemepickerComponent,
    IsenumgridComponent,
    IstimebarComponent,
    IscollapsibleComponent,
    IsfilterComponent,
    IsStyleInputComponent,
    IsStyleSelectComponent,
    IsleafexpressionComponent,
    IsexpressionComponent,
    IsgriddropdownComponent,
    IsfilterpanelComponent,
    DateValueAccessor,
    GmapsComponent,
    IsenumpanelComponent,
    IscheckboxpanelComponent,
    IsemailComponent,
    IsexpfilterComponent,
    IsexpcontainerComponent,
    AndOrComponent,
    EditenumComponent,
    Isinput2Component,
    Issizeinput2Component,
    IsperiodselectorComponent,
    Menuhandler3Component,
    Menuhandleritem3Component,
    IsimageComponent,
    IsframeComponent,
    Isradio2Component,
    IslabeldatadelimiterComponent,
    IsCompiteratorComponent,
    IserrorComponent,
    TfrxqueryComponent,
    IsuploadComponent,
    Isyearplanner2Component,
    Ismonth2Component,
    Isday2Component,
    SpinnerOverlayComponent,
    IsclockComponent,
    SignatureComponent,
    IssignatureComponent,
    IstoggleComponent,
    //IsloaderComponent,
    IsgmapsComponent,
    SliderComponent,
    IsfrequentionComponent,
    IsfileinputComponent,
  ],
  imports: [
    CommonModule,
    AngularSplitModule,
    ReactiveFormsModule,
    CdkTreeModule,
    CdkTableModule,
    CdkStepperModule,
    MatButtonModule,
    MatRadioModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatDividerModule,
    MatCardModule,
    MatDatepickerModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    MatNativeDateModule,
    MatListModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatTreeModule,
    MatStepperModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    // BrowserAnimationsModule,
    //WidgetsModule,
    HighlightModule,
    //ScrollingModule,
    DragDropModule,
    FormsModule,
    IsBaseModule,
    IsBaseControlsModule,
    NgxsModule.forFeature([EnumState, IsApplicationState, IsEventState]),
    TreeModule,
    WidgetsRegistryModule.forChild({
      module: { ngModule: IscompModule },
      components: {
        istoolbar: IstoolbarComponent,
        isbutton: IsbuttonComponent,
        islabel: IslabelComponent,
        ismenu: IsmenuComponent,
        isinput: InputComponent,
        isinput2: Isinput2Component,
        isinputenum: IsinputenumerateComponent,
        istree: IstreeComponent,
        iswizard: IswizardComponent,
        iswizpage: IswizpageComponent,
        isdialog: IsdialogComponent,
        isicon: IsiconComponent,
        isinputtest: InputComponent,
        isselect: SelectComponent,
        isdate: DateComponent,
        isdatefull: DatefullComponent,
        isenum2: Isenum2Component,
        isenumpanel: IsenumpanelComponent,
        isnumber: NumberComponent,
        istimefull: TimeFullComponent,
        istimesingle: TimesingleComponent,
        iscolorpicker: IscolorpickerComponent,
        issizeinput: IssizeinputComponent,
        isslider: IssliderComponent,
        isiconoption: IsiconoptionComponent,
        ispageformtest: IspageformtestComponent,
        istaginput: IstaginputComponent,
        isenumselect: IsenumselectComponent,
        ischeckbox: IscheckboxComponent,
        isradio: IsradioComponent,
        isradio2: Isradio2Component,
        ismenu2: Ismenu2Component,
        ismenu2item: Ismenuitem2Component,
        ispanel: PanelComponent,
        issplitter: SplitterComponent,
        speedometer: SpeedometerComponent,
        isformgroup: FormgroupComponent,
        menuhandler: MenuhandlerComponent,
        menuhandleritem: MenuhandleritemComponent,
        menuhandler3: Menuhandler3Component,
        menuhandleritem3: Menuhandleritem3Component,
        isgrid: IsgridComponent,
        iscel: IscelComponent,
        editcel: EditcelComponent,
        ischeckboxcel: IscheckboxcelComponent,
        isheadercel: Isheadercolumn1Component,
        isheaderselect: IsselectheaderComponent,
        editconfig: EditconfigComponent,
        islabeldata: IslabeldataComponent,
        islabeldatadelimiter: IslabeldatadelimiterComponent,
        isstylefunc: StylefuncComponent,
        isyearplanner: Isyearplanner2Component,
        ismonth: IsmonthComponent,
        isday: IsdayComponent,
        isvariableplanner: IsVariablemonthPlanner,
        isthemepicker: IsthemepickerComponent,
        istimebar: IstimebarComponent,
        isenumgrid: IsenumgridComponent,
        isfilter: IsfilterComponent,
        isexpfilter: IsexpfilterComponent,
        iscustomfilter: IsgriddropdownComponent,
        ischeckboxpanel: IscheckboxpanelComponent,
        isemail: IsemailComponent,
        editenum: EditenumComponent,
        issizeinput2: Issizeinput2Component,
        isperiodselector: IsperiodselectorComponent,
        isimage: IsimageComponent,
        isframe: IsframeComponent,
        iscompiterator: IsCompiteratorComponent,
        iserror: IserrorComponent,
        isquery: TfrxqueryComponent,
        isupload: IsuploadComponent,
        isclock: IsclockComponent,
        issignature: IssignatureComponent,
        istoggle: IstoggleComponent,
        isgmaps: IsgmapsComponent,
        isfrequention: IsfrequentionComponent,
        isfileinput: IsfileinputComponent
      },
    }),
    //    WidgetsLoaderModule,
  ],
  exports: [
    IswizardComponent,
    SelectionGridComponent,
    CdkTreeModule,
    CdkTableModule,
    CdkStepperModule,
    MatButtonModule,
    MatRadioModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatGridListModule,
    MatDividerModule,
    MatCardModule,
    MatDatepickerModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    MatNativeDateModule,
    MatListModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatTreeModule,
    MatStepperModule,
    HighlightModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    AngularSplitModule,
    TreeModule,
    IsinputenumerateComponent,
    IsinputComponent,
    Isinput2Component,
    Issizeinput2Component,
    IsinputenumerateComponent,
    IswizpageComponent,
    IswizardComponent,
    IsApplicationComponent,
    IstoolbarComponent,
    IsbuttonComponent,
    IsmenuComponent,
    IslabelComponent,
    SelectionGridComponent,
    RightclickmenuComponent,
    IsgridComponent,
    IstreeComponent,
    IsworkorderplannerComponent,
    Isheadercolumn1Component,
    IsdialogComponent,
    IsiconComponent,
    //    IscontextoverlayComponent,
    SelectComponent,
    InputComponent,
    DateComponent,
    NumberComponent,
    TimeFullComponent,
    TimesingleComponent,
    DatefullComponent,
    IscolorpickerComponent,
    IssizeinputComponent,
    IssliderComponent,
    SpeedometerComponent,
    IsiconoptionComponent,
    IspageformtestComponent,
    IstaginputComponent,
    IspageformtestitemComponent,
    Ismenu2Component,
    Ismenuitem2Component,
    PagedesignerComponent,
    IsthemaComponent,
    IsgridComponent,
    IsheaderComponent,
    IsbodyComponent,
    IsfooterComponent,
    IscelComponent,
    EditcelComponent,
    IscheckboxcelComponent,
    IsselectheaderComponent,
    IscheckboxComponent,
    IsradioComponent,
    Isenum2Component,
    IsenumpanelComponent,
    IsenumgridComponent,
    EditconfigComponent,
    FieldselectorComponent,
    IslabeldataComponent,
    IsPlanner,
    IsVariablemonthPlanner,
    IsyearplannerComponent,
    IsmonthComponent,
    IsdayComponent,
    IsthemepickerComponent,
    IscollapsibleComponent,
    IsStyleInputComponent,
    IsStyleSelectComponent,
    DateValueAccessor,
    IscheckboxpanelComponent,
    IsemailComponent,
    IsBaseModule,
    IsBaseControlsModule,
    Menuhandler3Component,
    Menuhandleritem3Component,
    IsimageComponent,
    IsframeComponent,
    IserrorComponent,
    TfrxqueryComponent,
    IsuploadComponent,
    IsclockComponent,
    IsgmapsComponent,
    IsfrequentionComponent,
    IsfileinputComponent
  ],
  providers: [
    //IsCloudService,
    ConfigService,
    MessageService,
    HttpErrorHandler,
    //AlertService,
    IscompilerService,
    SpinnerOverlayService,
    // CvcolorPipe,
    // WidgetsModule.forChild()
    //  WidgetRegistry,DefaultWidtgetRegistry
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: SpinnerInterceptor,
    //   multi: true,
    // },
  ],
})
export class IscompModule {
  #instanceNr = instanceNr++;

  constructor(
    moduleRef: NgModuleRef<IscompModule>,
    widgetsRegistryService: WidgetsRegistryService
  ) {
    widgetsRegistryService.setEagerModuleRef('IscompModule', moduleRef);
    console.log(
      `%cClass: IscompModule, Function: constructor(this.#instanceNr): `,
      'color: black;',
      this.#instanceNr
    );
  }
}
