import {
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ComponentsService, EventMap, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { EnumerationItem } from '../../isenum/enum.model';
import { EnumState } from '../../isenum/enum.state';
import { debounceTime, map, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IsGridRegistry } from '../../isgrid/isgrid.service';
import { LoadEnumlist } from '../../isenum/enum.action';
import { EnumToDataset } from '../../isapplication/isapplication.action';
import { tap } from 'lodash';

@Component({
  selector: 'isenum',
  templateUrl: './isenum2.component.html',
  styleUrls: ['./isenum2.component.scss'],
})
export class Isenum2Component extends IscontrolComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('grid', { read: TemplateRef }) grid: TemplateRef<any>;
  @ViewChild('searchdesc', { read: ElementRef }) public searchdesc: ElementRef<any>;
  @ViewChild('searchnumber', { read: ElementRef }) public searchnumber: ElementRef<any>;

  dialogRef: MatDialogRef<any>;
  @Input() initialValue: string;
  @Input() label: string;
  _enum:string | null;
  @Input() set enum(value:string){
     this._enum = value;
  };
  get enum():string{
    return this._enum;
  }
  @Input() multiselect = false;
  @Input() disabled = false;
  @Input() connectionid: number = null;

  datax: any[];
  alldata: any[];
  listname: string;
  enumerationlist$: Observable<EnumerationItem[]>;
  enumlist: any;
  private subscriptions: Array<Subscription> = [];
  selectedOptions: EnumerationItem[] = [];

  controlidID: number;
  filterID: number;
  controlidDesc: number;
  prevsearch: any;
  //controlidsearchDesc: number;
  //controlidsearchID: number;

  formInput = true;
  @Input('formControlID') formControlID: FormControl;
  //@Input('searchDesc') searchDesc: FormControl;
  //@Input('searchID') searchID: FormControl;
  @ViewChild('searchDesc') searchdescField: FormControl;  
  @ViewChild('searchID') searchidField: FormControl;

  @Input('formFilterID') formFilterID: FormControl;
  searchGroup: FormGroup;
  formControlDesc: FormControl;
  eventmap: EventMap;

  hasLabel: boolean = true;
  hasID: boolean = true;
  hasDescription: boolean = true;

  gridid: number = null;
  leading: string = 'id';
  columnListening: string = '';
  filter: number = null;
  autoselectfirst: false;
  idChanged: any | null = null;
  valueCleared: any | null = null;
  loadoffline: boolean;
  customclick: Function;
  gridclass: string;
  mobile: number = 0;
  keyboardHeight = '173px';
  warning = false;
  translation: any | null = null;
  
  @Input('enumtype') enumtype: string = 'number';
  get fcid() {
    return this.formControlID;
  }

  get fcdesc() {
    return this.formControlDesc;
  }

  _filtervalue: number = null;
  set filtervalue(value: number) {
    if (value != this._filtervalue) {
      if (!this.filterID) {
        this.filterID = super.initControl(this.id.toString() + '.filterid', null, 'filterid');
        this.formFilterID = super.getFormControl(this.filterID);
      }
      this.formFilterID.setValue(value);
    }
  }

  columns = [
    { name: 'id', label: 'No', width: '25%' },
    { name: 'description', label: 'Libelle', width: '75%' },
  ];

  //subscribtions: Array<Subscription | undefined> = [];

  constructor(
    public comp: ComponentsService,
    public outlet: Outlet2Service,
    public store: Store,
    private gridReg: IsGridRegistry,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.listname = this.id.toString() + '.enumdesc';
      this.controlidID = super.initControl(this.id.toString() + '.enumid', null, 'enumid');
      this.controlidDesc = super.initControl(this.id.toString() + '.enumdesc', null, 'enumdesc', false);
      this.formControlID = super.getFormControl(this.controlidID);
      this.formControlDesc = super.getFormControl(this.controlidDesc);
      if (this.comp.isMobile()) {
        //this.controlidsearchID = super.initControl(this.id.toString() + '.enumdesc', null, 'searchid', false);
        //this.controlidsearchDesc = super.initControl(this.id.toString() + '.enumdesc', null, 'searchdesc', false);
        this.searchdescField = new FormControl(null, []);
        this.searchidField = new FormControl(null, []);
        this.searchGroup = new FormGroup({ searchID: this.searchidField, searchDesc: this.searchdescField });
        this.prevsearch = {searchID:null,searchDesc:null};
      }

      if (this.filter) {
        this.filterID = super.initControl(this.id.toString() + '.filterid', null, 'filterid');
        this.formFilterID = super.getFormControl(this.filterID);
      }
    } else {
      this.formControlID = this.formControlID
        ? this.formControlID
        : new FormControl(this.leading == 'id' ? this.initialValue : '');
      this.formControlDesc = this.formControlDesc
        ? this.formControlDesc
        : new FormControl(this.leading == 'description' ? this.initialValue : '');
      this.id = this.outlet.uniqueId;
    }

    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        this.datax = filterFn(this.enum);
        this.alldata = [];
        if (this.filter) {
          this.alldata = this.datax ? [...this.datax] : null;
        }
        if (this.datax) {
          if (this.autoselectfirst && this.datax.length > 0) {
            this.autoselectfirst = false;
            this.formControlID.setValue(this.datax[0].id);
          }

          if (this.multiselect) {
            // this.selectedOptions = this.datax;
            // this.updateIDString(this.datax);
            // this.comp.formcontrols.get(`${this.id}.select`)?.formcontrol.setValue(this.datax);
          }
        }
        return this.datax;
      })
    );

    if (this.enum) {
      this.store.dispatch(new LoadEnumlist(this.enum, this.id, this.connectionid, this.loadoffline, this.enumtype));
    }

    if (this.gridid) {
      let subToControl = this.leading == 'id' ? this.formControlDesc : this.formControlID;
      let sub: Subscription | undefined;
      sub = subToControl.valueChanges.subscribe((s) => {
        //console.log(s, this.columnListening);
        let grid = this.comp.widgets.get(this.gridid).instance.view;
        let celCompRef = grid.celCompRef[grid.selectedRowId][this.columnListening];
        //celCompRef.instance.label = s;

        grid.datax[grid.scrolledItems + grid.selectedRowId][this.columnListening] = s;
        grid.fillCell(celCompRef, grid.scrolledItems + grid.selectedRowId, grid.columns[this.columnListening]);
        //debugger;
        //[this.columnListening] =s;
        //debugger;
        // Set hier de andere kolom...
        this.subscriptions.forEach((sub) => {
          sub.unsubscribe();
        });
        this.subscriptions = [];
      });
      this.subscriptions.push(sub);
    }
  }

  ngAfterViewInit() {
    if (this.comp.isMobile()) {
      this.mobile = 2;
    }
    this.formControlID.valueChanges.subscribe((a) => {
      if (a) {
        //console.log('enum2 desc ', this.id, ' - ', a);
        this.setDesc(a);
        if (this.idChanged) {
          this.idChanged(a);
        }
      }
    });

    
  }

  preset(num: number) {
    let subbed = false;
    let immediateUnSub = false;

    const sub = this.enumerationlist$.subscribe((e) => {
      if (e) {
        // enumComp.formControlID.setValue(e.length > 0? e[0].id : null);
        this.formControlID.setValue(num);
        if (subbed) {
          sub?.unsubscribe();
        }
        immediateUnSub = true;
      }
      subbed = true;
    });

    if (immediateUnSub) {
      sub.unsubscribe();
    }
  }

  afterformReady() {
    if (this.formControlID) {
      if (this['valueChanges']) {
        this.formControlID.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControlID.value);
        });
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.comp.formcontrols.get(this.controlidID.toString()).name = newVal + 'ID';
    this.comp.formcontrols.get(this.controlidDesc.toString()).name = newVal + 'Desc';
  }

  enumValueChanges(val) {
    // const cmpRef = this.gridReg.get(this.id);
    if (val != this.enum) {
      this.enum = val;
      this.store.dispatch(new LoadEnumlist(this.enum, this.id, this.connectionid, this.loadoffline));
    }
  }

  setDesc(id) {
    if (this.datax?.length) {
      const en = this.datax.find((en) => en.id == id);
      if (this.formControlDesc?.value != en?.description) {
        this.formControlDesc.setValue(en ? en.description : null, { onlySelf: true });
      }
    }

    // let sub: Subscription | undefined = undefined;
    // sub = this.enumerationlist$.pipe(take(1)).subscribe((list) => {
    //   if (list) {
    //     const en = list.find((en) => en.id == id);
    //     this.formControlDesc.setValue(en ? en.description : null);
    //     sub.unsubscribe();
    //   }
    // });
  }

  findDesc(event) {
    if (event.key == 'Tab') {
      const eid = this.formControlID.value;
      this.setDesc(eid);
    }
  }

  setID(desc) {
    if (this.datax) {
      const en = this.datax.find((en) => en.description == desc);
      if (this.formControlID?.value != en?.id) {
        this.formControlID.setValue(en ? en.id : null);
      }
    }

    // let sub: Subscription | undefined = undefined;
    // sub = this.enumerationlist$.pipe(take(1)).subscribe((list) => {
    //   if (list) {
    //     const en = list.find((en) => en.description == desc);
    //     this.formControlID.setValue(en ? en.id : null);
    //     sub.unsubscribe();
    //   }
    // });
  }

  findID(event) {
    if (event.key == 'Tab') {
      const eid = this.formControlID.value;
      this.setDesc(eid);
    }
  }

  onDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  afterview() {
    const gridref = this.gridReg.get(this.id);
    gridref.removedataset = false;

    gridref.loadData({
      //dsname: this.enum,
      data: this.datax,
      params: {},
      definition: this.columns ? 0 : 1,
      columns: this.columns,
      rowfunctions: null,
      account: null,
      masterdetail: null,
      master: null,
      fixparams: null,
    });

    if (this.hasID) {
      gridref.setInitalRow('id', this.formControlID.value);
    } else {
      gridref.setInitalRow('description', this.formControlDesc.value);
    }

    gridref.setSelectedData([...this.selectedOptions]);
    if (this.multiselect) {
      gridref.updateCheckboxes();
      this.subscriptions.push(
        this.comp.formcontrols.get(`${this.id}.select`)?.formcontrol.valueChanges.subscribe((selected) => {
          this.selectedOptions = selected;
          this.updateIDString(this.selectedOptions);
        })
      );
    }
  }

  button() {
    if (this.customclick) {
      this.customclick();
    } else if (!this.disabled) {
      this.NgZone.run(() => {
        if (this.formFilterID && this.filter) {
          console.log('filtered ', this.alldata);
          this.datax = this.alldata.filter((item) => this.formFilterID.value === parseInt(item.data));
        }
        if (this.comp.isMobile()) {
          this.gridclass = 'isgridmobile';

          this.dialogRef = this.dialog.open(this.grid, {
            disableClose: true,
            width: '100vw',
            height: '580px',
            maxWidth: '100vw',
            //minHeight: 'calc(100% - 250px)',
            maxHeight: '100vh',
            //panelClass: 'custom-modalbox',
            hasBackdrop: false,
            position: {
              top: '0px',
              left: '0px'
            }
          });

          this.dialogRef.afterClosed().subscribe((result) => {
            console.log('Dialog has closed ');
          });

          this.dialogRef.afterOpened().subscribe((result) => {

            if (this.comp.isMobile()){
              const bottomBar = document.getElementById('bottombar');
              const viewport = window.visualViewport;
        
              function resizeHandler(ev) {
                //bottomBar.style.display = viewport.scale > 1.3 ? "none" : "block";
                console.log('Visual viewport resize',ev);
                console.log('visualViewport : height ',window.innerHeight,' width : ',window.innerWidth);
                console.log('layoutViewport : height ',document.documentElement.clientHeight,' width : ',document.documentElement.clientWidth);
              }
        
              window.visualViewport.addEventListener('resize', resizeHandler);
            }


            const gridref = this.gridReg.get(this.id);
            gridref.browsemode = 1;

            const foc = (element) => {
              element.focus();
            }

            //(<any>this.searchdescField).nativeElement.focus();
            this.searchGroup.valueChanges.subscribe((data) => {
              console.log('typeAhead : ', data);
              if (data.searchID != this.prevsearch.searchID){
                try {
                const s = data.searchID?.toString();                
                const diff = data.searchDesc?.length<this.prevsearch.searchID?.length ? 'Backspace' : s?.replace(this.prevsearch.searchID, '')
                console.log('search input : ',diff)
                this.prevsearch.searchID = s;
                gridref.searchData('id',diff,250,foc,(<any>this.searchidField).nativeElement);
                } catch(e) {
                  foc((<any>this.searchidField).nativeElement);
                }  
                
              } 
              if (data.searchDesc != this.prevsearch.searchDesc){
                try {
                const diff = data.searchDesc?.length<this.prevsearch.searchDesc?.length ? 'Backspace' : data.searchDesc?.replace(this.prevsearch.searchDesc, '')                
                this.prevsearch.searchDesc = data.searchDesc;
                console.log('search input : ',diff)
                if (gridref.browsemode!=1){
                  gridref.searchData('description',diff,250,foc,(<any>this.searchdescField).nativeElement);
                } else {  
                  gridref.filterData('description', diff);
                  this.searchdesc.nativeElement.focus();
                }
                } catch(e){
                  foc((<any>this.searchdescField).nativeElement);
                }
                
              }

            });
          });
        } else {
          this.gridclass = 'isgrid';
          this.dialogRef = this.dialog.open(this.grid, { disableClose: true });
        }
        this.cd.detectChanges();
      });
    }

    /*
    this.dialog.open(IsenumgridComponent , {
      autoFocus: false,
      //disableClose: true,
      data: {
        enum: this.enum,
        id: this.id
      }
    });
    */
  }

  gridButtonOK() {
    const gridref = this.gridReg.get(this.id);
    const id = gridref.datax[gridref.selectedRowId + gridref.scrolledItems].id;
    const desc = gridref.datax[gridref.selectedRowId + gridref.scrolledItems].description;

    //console.log(id, desc);

    if (!this.multiselect) {
      this.formControlID.setValue(id, { onlySelf: true });
      this.formControlID.markAsDirty();
      //this.formControlDesc.setValue(desc, { onlySelf: true });
    } else {
      const selected = gridref.datasets.get('selected');

      this.comp.formcontrols.get(`${this.id}.select`)?.formcontrol.setValue(selected);
    }
    const event = new CustomEvent('enumvalue_changed', {
      bubbles: true,
      detail: { id: id },
    });
    this.el.nativeElement.dispatchEvent(event);
    //this.setDesc(id);
    this.gridButtonCancel();
  }

  dblclick(ev){
    if (ev.target.tagName === "ISCEL") this.gridButtonOK();
  }

  gridButtonCancel() {
    this.NgZone.run(() => {
      this.dialogRef.close();
      this.cd.detectChanges();
    });
  }

  reset() {
    if (!this.disabled) {
      this.formControlID.setValue(null);
      //this.formControlDesc.setValue(null, { onlySelf: true });
      this.selectedOptions = [];
      if (this.valueCleared) {
        this.valueCleared();
      }
    }
  }

  removeTag(tag) {
    this.selectedOptions = this.selectedOptions.filter((option) => option.id !== tag.id);
    this.comp.formcontrols.get(`${this.id}.select`)?.formcontrol.setValue(this.selectedOptions);
    this.updateIDString(this.selectedOptions);
    this.cd.detectChanges();
  }

  updateIDString(selected) {
    let str = '';
    selected.forEach((option) => {
      str += `${option.id},`;
    });
    str = str.substr(0, str.length - 1);
    this.formControlID.setValue(str);
  }

  click() {
    //console.log('clicked');
  }

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }

  ngOnDestroy() {
    //this.subscribtions.map((s) => s?.unsubscribe());
  }
}
